@font-face {
  font-family: 'sim-font';
  src: url('../assets/fonts/sim-font.eot?t=1726469568251'); /* IE9*/
  src:
    url('../assets/fonts/sim-font.eot?t=1726469568251#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/sim-font.woff2?t=1726469568251')
      format('woff2'),
    url('../assets/fonts/sim-font.woff?t=1726469568251') format('woff'),
    url('../assets/fonts/sim-font.ttf?t=1726469568251') format('truetype'),
    /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
      url('../assets/fonts/sim-font.svg?t=1726469568251#sim-font') format('svg'); /* iOS 4.1- */
}

i[class^='icon-'],
i[class*=' icon-'] {
  font-family: 'sim-font' !important;
  //font-size:16px; //NOTE: this prevents override
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--ui-theme-ui-icon);
}

.icon-slide-custom:before {
  content: '\ea01';
}
.icon-3d-view-first:before {
  content: '\ea02';
}
.icon-3d-view-second:before {
  content: '\ea03';
}
.icon-3d-view-third:before {
  content: '\ea04';
}
.icon-add:before {
  content: '\ea05';
}
.icon-add-audio:before {
  content: '\ea06';
}
.icon-add-docs:before {
  content: '\ea07';
}
.icon-add-picture:before {
  content: '\ea08';
}
.icon-add-video:before {
  content: '\ea09';
}
.icon-arrow-big:before {
  content: '\ea0a';
}
.icon-arrow-small:before {
  content: '\ea0b';
}
.icon-assets:before {
  content: '\ea0c';
}
.icon-audio:before {
  content: '\ea0d';
}
.icon-back:before {
  content: '\ea0e';
}
.icon-bell:before {
  content: '\ea0f';
}
.icon-bell-filled:before {
  content: '\ea10';
}
.icon-brush:before {
  content: '\ea11';
}
.icon-brush-first:before {
  content: '\ea12';
}
.icon-calendar:before {
  content: '\ea13';
}
.icon-camera:before {
  content: '\ea14';
}
.icon-camera-on:before {
  content: '\ea15';
}
.icon-card:before {
  content: '\ea16';
}
.icon-check:before {
  content: '\ea17';
}
.icon-circle:before {
  content: '\ea18';
}
.icon-click:before {
  content: '\ea19';
}
.icon-compare-view:before {
  content: '\ea1a';
}
.icon-cross:before {
  content: '\ea1b';
}
.icon-cross-rounded:before {
  content: '\ea1c';
}
.icon-dashboard:before {
  content: '\ea1d';
}
.icon-details:before {
  content: '\ea1e';
}
.icon-document:before {
  content: '\ea1f';
}
.icon-dollar:before {
  content: '\ea20';
}
.icon-download:before {
  content: '\ea21';
}
.icon-edit-first:before {
  content: '\ea22';
}
.icon-edit-second:before {
  content: '\ea23';
}
.icon-error:before {
  content: '\ea24';
}
.icon-error-fill:before {
  content: '\ea25';
}
.icon-euro:before {
  content: '\ea26';
}
.icon-events:before {
  content: '\ea27';
}
.icon-filter:before {
  content: '\ea28';
}
.icon-filter-filled:before {
  content: '\ea29';
}
.icon-folder:before {
  content: '\ea2a';
}
.icon-full-screen:before {
  content: '\ea2b';
}
.icon-full-screen-second:before {
  content: '\ea2c';
}
.icon-hamburger:before {
  content: '\ea2d';
}
.icon-home:before {
  content: '\ea2e';
}
.icon-info:before {
  content: '\ea2f';
}
.icon-info-filled:before {
  content: '\ea30';
}
.icon-info-first:before {
  content: '\ea31';
}
.icon-invisible:before {
  content: '\ea32';
}
.icon-invitation:before {
  content: '\ea33';
}
.icon-letter:before {
  content: '\ea34';
}
.icon-link-bold:before {
  content: '\ea35';
}
.icon-link-first:before {
  content: '\ea36';
}
.icon-list:before {
  content: '\ea37';
}
.icon-location-map:before {
  content: '\ea38';
}
.icon-location-map-full:before {
  content: '\ea39';
}
.icon-location-note:before {
  content: '\ea3a';
}
.icon-location-note-full:before {
  content: '\ea3b';
}
.icon-lock:before {
  content: '\ea3c';
}
.icon-map:before {
  content: '\ea3d';
}
.icon-measure-tool:before {
  content: '\ea3e';
}
.icon-more-first:before {
  content: '\ea3f';
}
.icon-more-second-33:before {
  content: '\ea40';
}
.icon-more-second:before {
  content: '\ea41';
}
.icon-note:before {
  content: '\ea42';
}
.icon-notes:before {
  content: '\ea43';
}
.icon-organization:before {
  content: '\ea44';
}
.icon-organization-filled:before {
  content: '\ea45';
}
.icon-organization-roles:before {
  content: '\ea46';
}
.icon-organization-roles-filled:before {
  content: '\ea47';
}
.icon-overview:before {
  content: '\ea48';
}
.icon-overview-filled:before {
  content: '\ea49';
}
.icon-picture:before {
  content: '\ea4a';
}
.icon-play:before {
  content: '\ea4b';
}
.icon-play-second:before {
  content: '\ea4c';
}
.icon-preferences:before {
  content: '\ea4d';
}
.icon-profile:before {
  content: '\ea4e';
}
.icon-project-filled:before {
  content: '\ea4f';
}
.icon-project-list:before {
  content: '\ea50';
}
.icon-prtscr:before {
  content: '\ea51';
}
.icon-remove:before {
  content: '\ea52';
}
.icon-replace:before {
  content: '\ea53';
}
.icon-rotate:before {
  content: '\ea54';
}
.icon-rotation:before {
  content: '\ea55';
}
.icon-save:before {
  content: '\ea56';
}
.icon-screens:before {
  content: '\ea57';
}
.icon-search:before {
  content: '\ea58';
}
.icon-secured:before {
  content: '\ea59';
}
.icon-send:before {
  content: '\ea5a';
}
.icon-settings:before {
  content: '\ea5b';
}
.icon-settings-filled:before {
  content: '\ea5c';
}
.icon-sort:before {
  content: '\ea5d';
}
.icon-sort-second:before {
  content: '\ea5e';
}
.icon-square:before {
  content: '\ea5f';
}
.icon-stages:before {
  content: '\ea60';
}
.icon-star:before {
  content: '\ea61';
}
.icon-star-filled:before {
  content: '\ea62';
}
.icon-statistics:before {
  content: '\ea63';
}
.icon-stop:before {
  content: '\ea64';
}
.icon-subscription:before {
  content: '\ea65';
}
.icon-subscription-filled:before {
  content: '\ea66';
}
.icon-success:before {
  content: '\ea67';
}
.icon-sync:before {
  content: '\ea68';
}
.icon-tags:before {
  content: '\ea69';
}
.icon-tags-filled:before {
  content: '\ea6a';
}
.icon-teams:before {
  content: '\ea6b';
}
.icon-teams-filled:before {
  content: '\ea6c';
}
.icon-team-templates:before {
  content: '\ea6d';
}
.icon-team-templates-filled:before {
  content: '\ea6e';
}
.icon-team-templates-second:before {
  content: '\ea6f';
}
.icon-time:before {
  content: '\ea70';
}
.icon-time-trigger:before {
  content: '\ea71';
}
.icon-trash:before {
  content: '\ea72';
}
.icon-unlock:before {
  content: '\ea73';
}
.icon-upload:before {
  content: '\ea74';
}
.icon-users:before {
  content: '\ea75';
}
.icon-user-filled:before {
  content: '\ea76';
}
.icon-video:before {
  content: '\ea77';
}
.icon-visible:before {
  content: '\ea78';
}
.icon-volume:before {
  content: '\ea79';
}
.icon-volume-x:before {
  content: '\ea7a';
}
.icon-warning:before {
  content: '\ea7b';
}
.icon-warning-filled:before {
  content: '\ea7c';
}
.icon-world:before {
  content: '\ea7d';
}
.icon-zoom-in:before {
  content: '\ea7e';
}
.icon-zoom-out:before {
  content: '\ea7f';
}
