@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,300&display=swap');

$fontSizeRegular: 16px;

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  color: var(--ui-theme-text-primary);
}

.text-regular {
  font-style: normal;
  font-weight: 400;
}

.text-bold {
  font-style: normal;
  font-weight: 700;
}
.text-small {
  font-size: 14px;
}
.text-normal {
  font-size: 16px;
}
span {
  color: var(--ui-theme-text-primary);
}

label {
  color: var(--ui-theme-ui-label);
  letter-spacing: 0.25px;
  font-size: 0.75rem;
  cursor: inherit;
}

h1 {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 32px;
}

h2 {
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 26px;
}

h3 {
  font-size: 1rem;
  font-weight: bold;
  line-height: 21px;
}

h4 {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 19px;
}

h5 {
  font-size: 0.75rem;
  font-weight: bold;
}

h6 {
  font-size: 0.75rem;
  font-weight: 500;
}

@mixin textRegularFont {
  overflow: hidden;
  color: var(--ui-theme-text-primary, rgba(255, 255, 255, 0.8));
  text-overflow: ellipsis;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 132%;
}

@mixin label2Bold {
  font-size: 14px;
  font-style: normal;
  line-height: 132%;
}

@mixin textRegular3Font {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 132%;
}

@mixin textRegular2Font {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 132%;
}

@mixin body2regular {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

@mixin body1regular {
  font-size: $fontSizeRegular;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

@mixin body2semibold {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

@mixin heading2Bold {
  font-size: var(--Typography-Sizes-heading-2, 20px);
  font-style: normal;
  font-weight: 700;
  line-height: 132%;
}

@mixin heading3semibold {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

@mixin textLabel3Regular {
  font-size: 12px;
  font-style: normal;
  line-height: 132%;
}

@mixin textLabel1Regular {
  font-size: $fontSizeRegular;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

@mixin heading2Regular {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 132%;
}

@mixin headingDisplay {
  font-size: 32px;
  font-style: normal;
  line-height: 132%;
}

@mixin heading1 {
  font-size: 24px;
  font-style: normal;
  line-height: 132%;
}
