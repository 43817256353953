
@font-face {
  font-family: "design-font";
  src: url('../assets/fonts/design-font.eot?t=1739283396811'); /* IE9*/
  src: url('../assets/fonts/design-font.eot?t=1739283396811#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url("../assets/fonts/design-font.woff2?t=1739283396811") format("woff2"),
  url("../assets/fonts/design-font.woff?t=1739283396811") format("woff"),
  url('../assets/fonts/design-font.ttf?t=1739283396811') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
  url('../assets/fonts/design-font.svg?t=1739283396811#design-font') format('svg'); /* iOS 4.1- */
}

i[class^="design-symbol-"], i[class*=" design-symbol-"] {
  display: block;
  font-family: 'design-font' !important;
  font-style:normal;
  line-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--ui-theme-ui-icon);
}

.design-symbol-account_circle:before { content: '\ea01'; }
.design-symbol-add:before { content: '\ea02'; }
.design-symbol-add_audio:before { content: '\ea03'; }
.design-symbol-add_circle:before { content: '\ea04'; }
.design-symbol-add_docs:before { content: '\ea05'; }
.design-symbol-add_picture:before { content: '\ea06'; }
.design-symbol-add_video:before { content: '\ea07'; }
.design-symbol-area_measurements:before { content: '\ea08'; }
.design-symbol-arrow_back:before { content: '\ea09'; }
.design-symbol-arrow_big:before { content: '\ea0a'; }
.design-symbol-arrow_drop_down:before { content: '\ea0b'; }
.design-symbol-arrow_forward:before { content: '\ea0c'; }
.design-symbol-arrow_right:before { content: '\ea0d'; }
.design-symbol-arrow_small:before { content: '\ea0e'; }
.design-symbol-assets:before { content: '\ea0f'; }
.design-symbol-attached_note:before { content: '\ea10'; }
.design-symbol-attach_file:before { content: '\ea11'; }
.design-symbol-audio:before { content: '\ea12'; }
.design-symbol-audio_file:before { content: '\ea13'; }
.design-symbol-back:before { content: '\ea14'; }
.design-symbol-bell:before { content: '\ea15'; }
.design-symbol-bell_filled:before { content: '\ea16'; }
.design-symbol-brush:before { content: '\ea17'; }
.design-symbol-brush_first:before { content: '\ea18'; }
.design-symbol-calendar:before { content: '\ea19'; }
.design-symbol-camera:before { content: '\ea1a'; }
.design-symbol-camera_on:before { content: '\ea1b'; }
.design-symbol-card:before { content: '\ea1c'; }
.design-symbol-check:before { content: '\ea1d'; }
.design-symbol-check_circle:before { content: '\ea1e'; }
.design-symbol-circle:before { content: '\ea1f'; }
.design-symbol-circle_fill:before { content: '\ea20'; }
.design-symbol-close:before { content: '\ea21'; }
.design-symbol-cloud:before { content: '\ea22'; }
.design-symbol-cloud_filled:before { content: '\ea23'; }
.design-symbol-compare_view:before { content: '\ea24'; }
.design-symbol-content_copy:before { content: '\ea25'; }
.design-symbol-cross:before { content: '\ea26'; }
.design-symbol-cross_rounded:before { content: '\ea27'; }
.design-symbol-cursor:before { content: '\ea28'; }
.design-symbol-cursor_fill:before { content: '\ea29'; }
.design-symbol-dashboard:before { content: '\ea2a'; }
.design-symbol-date_range:before { content: '\ea2b'; }
.design-symbol-delete:before { content: '\ea2c'; }
.design-symbol-details:before { content: '\ea2d'; }
.design-symbol-docks:before { content: '\ea2e'; }
.design-symbol-document:before { content: '\ea2f'; }
.design-symbol-document_file:before { content: '\ea30'; }
.design-symbol-dolar:before { content: '\ea31'; }
.design-symbol-download:before { content: '\ea32'; }
.design-symbol-edit_first:before { content: '\ea33'; }
.design-symbol-edit_second:before { content: '\ea34'; }
.design-symbol-error:before { content: '\ea35'; }
.design-symbol-error_fill:before { content: '\ea36'; }
.design-symbol-euro:before { content: '\ea37'; }
.design-symbol-events:before { content: '\ea38'; }
.design-symbol-executive_fill:before { content: '\ea39'; }
.design-symbol-expand_more:before { content: '\ea3a'; }
.design-symbol-export:before { content: '\ea3b'; }
.design-symbol-file:before { content: '\ea3c'; }
.design-symbol-file_download:before { content: '\ea3d'; }
.design-symbol-file_info:before { content: '\ea3e'; }
.design-symbol-filter:before { content: '\ea3f'; }
.design-symbol-filter_filled:before { content: '\ea40'; }
.design-symbol-folder:before { content: '\ea41'; }
.design-symbol-folder_copy:before { content: '\ea42'; }
.design-symbol-folder_copy_2:before { content: '\ea43'; }
.design-symbol-folder_fill:before { content: '\ea44'; }
.design-symbol-forward:before { content: '\ea45'; }
.design-symbol-full_screen:before { content: '\ea46'; }
.design-symbol-full_screen_second:before { content: '\ea47'; }
.design-symbol-grid_list:before { content: '\ea48'; }
.design-symbol-hamburger:before { content: '\ea49'; }
.design-symbol-home:before { content: '\ea4a'; }
.design-symbol-icon_note_issue_basic:before { content: '\ea4b'; }
.design-symbol-ic_baseline_folder:before { content: '\ea4c'; }
.design-symbol-image_file:before { content: '\ea4d'; }
.design-symbol-image_search:before { content: '\ea4e'; }
.design-symbol-info:before { content: '\ea4f'; }
.design-symbol-info_circle:before { content: '\ea50'; }
.design-symbol-info_filled:before { content: '\ea51'; }
.design-symbol-info_first:before { content: '\ea52'; }
.design-symbol-invisible:before { content: '\ea53'; }
.design-symbol-jpg:before { content: '\ea54'; }
.design-symbol-laptop:before { content: '\ea55'; }
.design-symbol-letter:before { content: '\ea56'; }
.design-symbol-letter_fill:before { content: '\ea57'; }
.design-symbol-link:before { content: '\ea58'; }
.design-symbol-link_bold:before { content: '\ea59'; }
.design-symbol-link_first:before { content: '\ea5a'; }
.design-symbol-list:before { content: '\ea5b'; }
.design-symbol-location:before { content: '\ea5c'; }
.design-symbol-location_1:before { content: '\ea5d'; }
.design-symbol-location_2:before { content: '\ea5e'; }
.design-symbol-lock:before { content: '\ea5f'; }
.design-symbol-manage_accounts:before { content: '\ea60'; }
.design-symbol-map:before { content: '\ea61'; }
.design-symbol-map_location:before { content: '\ea62'; }
.design-symbol-map_location_full:before { content: '\ea63'; }
.design-symbol-mark_email_read:before { content: '\ea64'; }
.design-symbol-measure_tool:before { content: '\ea65'; }
.design-symbol-minus:before { content: '\ea66'; }
.design-symbol-model_3d:before { content: '\ea67'; }
.design-symbol-more_first:before { content: '\ea68'; }
.design-symbol-more_horiz:before { content: '\ea69'; }
.design-symbol-more_second:before { content: '\ea6a'; }
.design-symbol-move:before { content: '\ea6b'; }
.design-symbol-note:before { content: '\ea6c'; }
.design-symbol-notes:before { content: '\ea6d'; }
.design-symbol-organization:before { content: '\ea6e'; }
.design-symbol-organization_filled:before { content: '\ea6f'; }
.design-symbol-organization_roles:before { content: '\ea70'; }
.design-symbol-organization_roles_filled:before { content: '\ea71'; }
.design-symbol-overview:before { content: '\ea72'; }
.design-symbol-overview_filled:before { content: '\ea73'; }
.design-symbol-paypal_svgrepo_com:before { content: '\ea74'; }
.design-symbol-people:before { content: '\ea75'; }
.design-symbol-picture:before { content: '\ea76'; }
.design-symbol-picture_as_pdf:before { content: '\ea77'; }
.design-symbol-play:before { content: '\ea78'; }
.design-symbol-play_second:before { content: '\ea79'; }
.design-symbol-plus:before { content: '\ea7a'; }
.design-symbol-preferences:before { content: '\ea7b'; }
.design-symbol-procore:before { content: '\ea7c'; }
.design-symbol-profile:before { content: '\ea7d'; }
.design-symbol-project_list:before { content: '\ea7e'; }
.design-symbol-project_list_filled:before { content: '\ea7f'; }
.design-symbol-prtscr:before { content: '\ea80'; }
.design-symbol-remove_circle:before { content: '\ea81'; }
.design-symbol-rename:before { content: '\ea82'; }
.design-symbol-replace:before { content: '\ea83'; }
.design-symbol-rotate:before { content: '\ea84'; }
.design-symbol-rotation:before { content: '\ea85'; }
.design-symbol-ruler:before { content: '\ea86'; }
.design-symbol-save:before { content: '\ea87'; }
.design-symbol-screens:before { content: '\ea88'; }
.design-symbol-search:before { content: '\ea89'; }
.design-symbol-search_bold:before { content: '\ea8a'; }
.design-symbol-secured:before { content: '\ea8b'; }
.design-symbol-send:before { content: '\ea8c'; }
.design-symbol-settings:before { content: '\ea8d'; }
.design-symbol-settings_filled:before { content: '\ea8e'; }
.design-symbol-sort:before { content: '\ea8f'; }
.design-symbol-sort_second:before { content: '\ea90'; }
.design-symbol-square:before { content: '\ea91'; }
.design-symbol-square_fill:before { content: '\ea92'; }
.design-symbol-stages:before { content: '\ea93'; }
.design-symbol-star:before { content: '\ea94'; }
.design-symbol-star_filled:before { content: '\ea95'; }
.design-symbol-statistics:before { content: '\ea96'; }
.design-symbol-stop:before { content: '\ea97'; }
.design-symbol-subscription:before { content: '\ea98'; }
.design-symbol-subscription_filled:before { content: '\ea99'; }
.design-symbol-success:before { content: '\ea9a'; }
.design-symbol-success_fill:before { content: '\ea9b'; }
.design-symbol-sync:before { content: '\ea9c'; }
.design-symbol-table_list:before { content: '\ea9d'; }
.design-symbol-tags:before { content: '\ea9e'; }
.design-symbol-tags_filled:before { content: '\ea9f'; }
.design-symbol-teams:before { content: '\eaa0'; }
.design-symbol-teams_filled:before { content: '\eaa1'; }
.design-symbol-team_templates:before { content: '\eaa2'; }
.design-symbol-team_templates_filled:before { content: '\eaa3'; }
.design-symbol-team_templates_second:before { content: '\eaa4'; }
.design-symbol-time:before { content: '\eaa5'; }
.design-symbol-time_trigger:before { content: '\eaa6'; }
.design-symbol-trash:before { content: '\eaa7'; }
.design-symbol-unlock:before { content: '\eaa8'; }
.design-symbol-upload:before { content: '\eaa9'; }
.design-symbol-upload_file:before { content: '\eaaa'; }
.design-symbol-users:before { content: '\eaab'; }
.design-symbol-user_filled:before { content: '\eaac'; }
.design-symbol-video:before { content: '\eaad'; }
.design-symbol-video_file:before { content: '\eaae'; }
.design-symbol-view_first:before { content: '\eaaf'; }
.design-symbol-view_second:before { content: '\eab0'; }
.design-symbol-view_third:before { content: '\eab1'; }
.design-symbol-visible:before { content: '\eab2'; }
.design-symbol-volume:before { content: '\eab3'; }
.design-symbol-volume_x:before { content: '\eab4'; }
.design-symbol-warning:before { content: '\eab5'; }
.design-symbol-warning_filled:before { content: '\eab6'; }
.design-symbol-world:before { content: '\eab7'; }
.design-symbol-zoom_in:before { content: '\eab8'; }
.design-symbol-zoom_in_fill:before { content: '\eab9'; }
.design-symbol-zoom_out:before { content: '\eaba'; }
.design-symbol-zoom_out_fill:before { content: '\eabb'; }