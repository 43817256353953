@use 'datepicker-range.scss';

* {
  box-sizing: border-box;
}

html {
  height: 100%;
  overflow: auto;
}

body {
  height: 100%;
}

body {
  margin: 0;
  font-size: 100%;
  font-family: 'Roboto', sans-serif; //TODO: import from typography!
  background-color: var(--ui-theme-grey-0);
  color: var(--ui-theme-text-primary);
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.text-secondary {
  color: var(--ui-theme-text-secondary);
}

//NOTE: unsure if this should be moved
.cdk-overlay-container {
  .ui-overlay-backdrop {
    background-color: rgba($color: #000000, $alpha: 0.45);
  }
}

.icon-vr::before {
  display: none !important;
}

:root {
  --page-toolbar-height: 64px;
}

.screenshot-pointer {
  pointer-events: none !important;
  min-height: 100%;
}
div.menu-panel-content.panel-background {
  background-color: var(--ui-theme-grey-700);
}

.cdk-global-scrollblock {
  overflow-y: auto !important;
}

.cdk-overlay-dark-backdrop {
  background: var(--ui-theme-ui-overlay) !important;
}

@supports selector(*::-webkit-scrollbar) {
  *::-webkit-scrollbar-track {
    background: var(--ui-theme-ui-bg-tetriary);
    border-radius: 4px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: var(--ui-theme-background-scroll);
    border-radius: 4px;
  }

  *::-webkit-scrollbar {
    width: 6px;
  }
}

@supports not selector(*::-webkit-scrollbar) {
  * {
    scrollbar-width: thin;
    scrollbar-color: var(--ui-theme-background-scroll)
      var(--ui-theme-ui-bg-tetriary);
  }
}

.uppercase {
  text-transform: uppercase;
}

.cdk-modal-overflow {
  overflow: auto;
}
