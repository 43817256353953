@forward 'design-font';
@forward 'toast';
@forward 'design-mixin/themes';

@forward '@angular/cdk/overlay-prebuilt';

@forward 'design-mixin/breakpoints' as bp-*;
@forward 'design-mixin/mixins' as mix-*;
@forward 'design-mixin/variables';
@forward '@angular/cdk/a11y-prebuilt';

*,
::after,
::before {
  box-sizing: border-box;
}
