$space: 10px;

@mixin base-toast {
    display: grid;
    position: absolute;
    row-gap: $space;
    z-index: 1000;
}

.toast-container-bottom-left,
.toast-container-bottom-right {
    @include base-toast;
    bottom: $space;
}

.toast-container-top-right,
.toast-container-top-left {
    @include base-toast;
    top: $space;
}

.toast-container-top-right,
.toast-container-bottom-right {
    right: $space;
}

.toast-container-top-left,
.toast-container-bottom-left {
    left: $space;
}